/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useQuery } from '@tanstack/react-query';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { createSearchParams, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { CurrencyDollarSimple, Money } from 'phosphor-react';
import moment from 'moment';
import { ReturnButton } from '../../../../../components/buttons/ReturnButton';
import { InputForm } from '../../../../../components/inputs/forms/InputForm';
import { FormBox } from '../../../../../components/formBox';
import SelectInputForm from '../../../../../components/inputs/forms/SelectInputForm';
import { IData, IPagination, ISelect } from '../../../../../types/shared';
import { ICompany, ITaxRegime, useCompanies } from '../../../../../hooks/useCompanies';
import { IClient } from '../../../../../hooks/useClients';
import {
  formatCNPJ,
  getFormattedLabelString,
  toCurrency,
} from '../../../../../utils/FormatFunctions';
import { Paginator } from '../../../../../components/paginator';
// import { DateToolbarFilter } from '../../../../components/DateToolbarFilter';

export function ModulesHomeSupervisorPartnerCompaniesPage() {
  const { getTaxRegime, getCompanies, getCompaniesFilters, getQuebrasTaxRegimeTotais } =
    useCompanies();
  const [showFilters, setShowFilters] = useState(false);
  const { itemId } = useParams();
  const location = useLocation();
  const [company] = useState(location.state?.company);

  const [searchParams] = useSearchParams();
  const dateStart = searchParams.get('date_start');
  const dateEnd = searchParams.get('date_end');

  const toggleFilters = () => setShowFilters(prev => !prev);
  const [filters, setFilters] = useSearchParams(
    createSearchParams({
      page: '1',
      size: '10',
      search: '',
      date_start: dateStart ?? moment().subtract(6, 'days').format('YYYY-MM-DD'),
      date_end: dateEnd ?? moment().format('YYYY-MM-DD'),
      regime_tributario: 'simples_nacional',
      socio_id: itemId ?? '',
    }),
  );

  const filtersFormMethods = useForm<{
    search: string;
    companyId: any;
    cliente: IClient;
    cidade: ISelect<string>;
    uf: ISelect<string>;
    grupo_de_empresas: ISelect<string>;
    tags: ISelect<string>;
    comercial: ISelect<string>;
    contabil: ISelect<string>;
    financeiro: ISelect<string>;
    contas_a_pagar: ISelect<string>;
    encerramento_anual: ISelect<string>;
    fiscal: ISelect<string>;
    imposto_de_renda: ISelect<string>;
    integracao_contabil: ISelect<string>;
    integracao_fiscal: ISelect<string>;
    pessoal: ISelect<string>;
    relacionamento_com_cliente: ISelect<string>;
    societario: ISelect<string>;
    situacao: 'ok' | 'aviso' | 'erro' | 'outro' | null;
  }>({
    mode: 'onChange',
    defaultValues: {
      search: filters.get('search') ?? '',
    },
  });

  const debounced = useDebounceCallback(values => {
    filters.set('search', values.search);
    filters.set('search', values.search);
    filters.set('id', values.companies?.external_id || '');
    filters.set('cidade', values.cidade?.value ?? '');
    filters.set('uf', values.uf?.value ?? '');
    filters.set('grupo_de_empresas', values.grupo_de_empresas?.value ?? '');
    filters.set('tags', values.tags?.value ?? '');
    filters.set('comercial', values.comercial?.value ?? '');
    filters.set('contabil', values.contabil?.value ?? '');
    filters.set('financeiro', values.financeiro?.value ?? '');
    filters.set('contas_a_pagar', values.contas_a_pagar?.value ?? '');
    filters.set('encerramento_anual', values.encerramento_anual?.value ?? '');
    filters.set('fiscal', values.fiscal?.value ?? '');
    filters.set('imposto_de_renda', values.imposto_de_renda?.value ?? '');
    filters.set('integracao_contabil', values.integracao_contabil?.value ?? '');
    filters.set('integracao_fiscal', values.integracao_fiscal?.value ?? '');
    filters.set('pessoal', values.pessoal?.value ?? '');
    filters.set('relacionamento_com_cliente', values.relacionamento_com_cliente?.value ?? '');
    filters.set('societario', values.societario?.value ?? '');
    setFilters(filters);
  }, 500);

  useEffect(() => {
    const subscription = filtersFormMethods.watch(values => {
      debounced(values);
    });

    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch, dateStart, dateEnd]);

  const queryKeySituacoesTotais = [
    'getSituationsTotals',
    {
      socio_id: itemId ?? '',
    },
  ];

  const { data: dataCompaniesSituacoes, isFetching: isFetchingSituacoesTotais } = useQuery<any>({
    queryKey: queryKeySituacoesTotais,
    queryFn: getQuebrasTaxRegimeTotais,
  });
  const situacoesTotais = dataCompaniesSituacoes;

  const queryKeyCompaniesFilters = ['CompaniesFilters'];
  const { data: CompaniesFiltersData } = useQuery({
    queryKey: queryKeyCompaniesFilters,
    queryFn: getCompaniesFilters,
  });

  const CompaniesFilters = CompaniesFiltersData;

  const { data: dataCompaniesSelect, isFetching: isFetchingCompanySelect } = useQuery<
    IData<ICompany[]>
  >({
    queryKey: ['CompaniesSelect', { page: 1, size: 30, search: '' }],
    queryFn: getCompanies,
  });

  const { data: dataTaxRegimeList, isFetching: isFetchingTaxRegimeList } = useQuery<
    IData<ITaxRegime[]>
  >({
    queryKey: ['taxRegimeList', Object.fromEntries([...Array.from(filters)])],
    queryFn: getTaxRegime,
  });

  const { items: companies } = dataCompaniesSelect?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  const { items: taxRegimeList, ...pagination } = dataTaxRegimeList?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="flex flex-col items-center justify-between gap-3 py-5 lg:flex-row">
        <div className="flex content-start md:w-1/3 lg:content-center ">
          <ReturnButton previousPage="modulesHome/supervisor/partner" />
        </div>
        <div className="mb-5 grid grid-cols-1 gap-3 md:grid-cols-2">
          {isFetchingSituacoesTotais ? (
            <div className="flex flex-col items-center gap-4">
              <Spinner size="lg" color="primary" />
              <span className="text-xl font-semibold">Carregando...</span>
            </div>
          ) : (
            <>
              <div
                className={`flex cursor-pointer items-center gap-3 rounded-3xl ${filters.get('regime_tributario') === 'lucro_presumido' ? 'bg-[#DCFAF8]' : 'bg-body'} p-5`}
                onClick={async () => {
                  const newFilters = new URLSearchParams(Object.fromEntries(filters));
                  if (filters.get('regime_tributario') === 'lucro_presumido') {
                    newFilters.delete('regime_tributario'); // Remove o filtro se já estiver selecionado
                  }
                  if (filters.get('regime_tributario')) {
                    await newFilters.set('page', '1');
                    newFilters.set('regime_tributario', 'lucro_presumido');
                  } else {
                    newFilters.set('regime_tributario', 'lucro_presumido');
                  }
                  setFilters(newFilters);
                }}
              >
                <div className="flex h-14 w-16 items-center justify-center rounded-full bg-[#DCFAF8] p-3">
                  <Money color="#16DBCC" className="size-7" />
                </div>

                <div className="flex w-full flex-col ">
                  <Text fontSize="14px" color="#718EBF">
                    Lucro presumido
                  </Text>
                  <Text fontSize="18px" fontWeight="bold">
                    {situacoesTotais?.lucro_presumido}
                  </Text>
                </div>
              </div>

              <div
                className={`flex cursor-pointer items-center gap-3 rounded-3xl ${filters.get('regime_tributario') === 'simples_nacional' ? 'bg-[#FFFECA]' : 'bg-body'} p-5`}
                onClick={() => {
                  const newFilters = new URLSearchParams(Object.fromEntries(filters));
                  if (filters.get('regime_tributario') === 'simples_nacional') {
                    newFilters.delete('regime_tributario'); // Remove o filtro se já estiver selecionado
                  }
                  if (filters.get('regime_tributario')) {
                    newFilters.set('page', '1');
                    newFilters.set('regime_tributario', 'simples_nacional');
                  } else {
                    newFilters.set('regime_tributario', 'simples_nacional');
                  }
                  setFilters(newFilters);
                }}
              >
                <div className="flex h-14 w-16 items-center justify-center rounded-full bg-[#FFEB99] p-3">
                  <CurrencyDollarSimple color="#4CAF50" className="size-7" />
                </div>

                <div className="flex w-full flex-col ">
                  <Text fontSize="14px" color="#718EBF">
                    Simples nacional
                  </Text>
                  <Text fontSize="18px" fontWeight="bold">
                    {situacoesTotais?.simples_nacional}
                  </Text>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="mb-2 grid grid-cols-1 gap-3 md:grid-cols-3">
        <Flex className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5">
          <div className="flex w-full flex-col ">
            <Text fontSize="10px" color="#718EBF">
              ID
            </Text>
            <Text fontSize="14px" fontWeight="bold">
              {company?.socio_id}
            </Text>
          </div>
        </Flex>
        <Flex className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5">
          <div className="flex w-full flex-col ">
            <Text fontSize="10px" color="#718EBF">
              Nome do sócio
            </Text>
            <Text fontSize="14px" fontWeight="bold">
              {company?.nome_socio}
            </Text>
          </div>
        </Flex>
        <Flex className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5">
          <div className="flex w-full flex-col ">
            <Text fontSize="10px" color="#718EBF">
              Inscrição
            </Text>
            <Text fontSize="14px" fontWeight="bold">
              {company?.inscricao}
            </Text>
          </div>
        </Flex>
      </div>
      <FormBox>
        <div className="flex flex-col gap-4  p-4">
          <div className="flex flex-col justify-between gap-2">
            <FormProvider {...filtersFormMethods}>
              <div className="flex flex-col justify-between gap-4 lg:flex-row">
                <div className="flex content-center items-center justify-center gap-3">
                  {/* colocar pt-10 caso precise ter filtro de data na div acima do comentário */}
                  <InputForm
                    name="search"
                    placeholder="Buscar por nome, cnpj, razão social"
                    boxShadow="0px 2px 4px #1E293B0D"
                    controlMb={0}
                  />
                  <Button
                    onClick={toggleFilters}
                    rightIcon={
                      showFilters ? (
                        <MdKeyboardArrowUp size="25px" />
                      ) : (
                        <MdKeyboardArrowDown size="25px" />
                      )
                    }
                  >
                    {showFilters ? 'Esconder Filtros' : 'Mostrar Filtros'}
                  </Button>
                </div>

                {/* <div className="justify-end ">
                  <DateToolbarFilter setFilters={setFilters} filters={filters} showMonthYearOnly />
                </div> */}
              </div>

              <div
                className={`transition-all duration-500 ${showFilters ? 'max-h-screen opacity-100' : 'max-h-0 overflow-hidden opacity-0'}`}
              >
                <div className="mt-4 flex justify-between gap-2 border-slate-400">
                  <form className="grid grid-cols-2 gap-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
                    <SelectInputForm<ICompany>
                      name="companies"
                      placeholder="Selecionar Empresa"
                      closeMenuOnSelect={false}
                      options={companies}
                      getOptionLabel={item => (item as ICompany).nome}
                      getOptionValue={item => String((item as ICompany).external_id)}
                      queryKeyName="CompaniesSelect"
                      getOptions={getCompanies}
                      isLoading={isFetchingCompanySelect}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="cidade"
                      placeholder="Filtrar por cidade"
                      options={getFormattedLabelString(CompaniesFilters?.cidade)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="uf"
                      placeholder="Filtrar por Estado"
                      options={getFormattedLabelString(CompaniesFilters?.uf)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="grupo_de_empresas"
                      placeholder="Filtrar por grupo de empresa"
                      options={getFormattedLabelString(CompaniesFilters?.grupo_de_empresas)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="tags"
                      placeholder="Filtrar por tags"
                      options={getFormattedLabelString(CompaniesFilters?.tags)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="comercial"
                      placeholder="Filtrar por comercial"
                      options={getFormattedLabelString(CompaniesFilters?.comercial)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="contabil"
                      placeholder="Filtrar pelo contábil"
                      options={getFormattedLabelString(CompaniesFilters?.contabil)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="financeiro"
                      placeholder="Filtrar pelo financeiro"
                      options={getFormattedLabelString(CompaniesFilters?.financeiro)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="contas_a_pagar"
                      placeholder="Filtrar por contas à pagar"
                      options={getFormattedLabelString(CompaniesFilters?.contas_a_pagar)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="encerramento_anual"
                      placeholder="Filtrar por encerramento anual"
                      options={getFormattedLabelString(CompaniesFilters?.encerramento_anual)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="fiscal"
                      placeholder="Filtrar por fiscal"
                      options={getFormattedLabelString(CompaniesFilters?.fiscal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="imposto_de_renda"
                      placeholder="filtrar por imposto de renda"
                      options={getFormattedLabelString(CompaniesFilters?.imposto_de_renda)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="integracao_contabil"
                      placeholder="Filtrar por integração contabil"
                      options={getFormattedLabelString(CompaniesFilters?.integracao_contabil)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="integracao_fiscal"
                      placeholder="Filtrar por integração fiscal"
                      options={getFormattedLabelString(CompaniesFilters?.integracao_fiscal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="pessoal"
                      placeholder="Filtrar por pessoal"
                      options={getFormattedLabelString(CompaniesFilters?.pessoal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="relacionamento_com_cliente"
                      placeholder="Filtrar por relacionamento com cliente"
                      options={getFormattedLabelString(
                        CompaniesFilters?.relacionamento_com_cliente,
                      )}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="societario"
                      placeholder="Filtrar por societário"
                      options={getFormattedLabelString(CompaniesFilters?.societario)}
                      controlMb={0}
                    />
                  </form>
                </div>
              </div>
            </FormProvider>
          </div>
          {isFetchingTaxRegimeList ? (
            <div className="flex flex-col items-center gap-4">
              <Spinner size="lg" color="primary" />
              <span className="text-xl font-semibold">Carregando...</span>
            </div>
          ) : taxRegimeList?.length === 0 ? (
            <div className="flex flex-col items-center gap-4">Nenhum item encontrado</div>
          ) : filters.get('search') && taxRegimeList?.length === 0 && !isFetchingTaxRegimeList ? (
            <div className="flex flex-col items-center gap-4">Nenhum item encontrado</div>
          ) : (
            <div className="grid grid-flow-row grid-cols-1 items-center justify-between gap-2">
              {taxRegimeList?.map(item => (
                <div className="flex gap-4 rounded-lg border p-4 shadow-lg lg:flex-col">
                  <div className="grid grid-cols-1 gap-9 lg:grid-cols-2">
                    <div className="grid grid-cols-2 gap-2 lg:grid-cols-3">
                      <div className="flex flex-col gap-2 ">
                        <span className="text-base font-bold">ID</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">{item?.external_id}</Text>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 ">
                        <span className="text-base font-bold">CNPJ</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">{formatCNPJ(item?.cnpj)}</Text>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 ">
                        <span className="text-base font-bold">Razão social</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">{item?.razao_social}</Text>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`grid grid-cols-2 gap-2 ${
                        filters.get('regime_tributario') !== 'lucro_presumido' &&
                        filters.get('regime_tributario')
                          ? 'lg:grid-cols-4'
                          : 'lg:grid-cols-3'
                      }`}
                    >
                      <div className="flex flex-col gap-2 ">
                        <span className="text-base font-bold">Regime tributário</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">{item.regime_tributario}</Text>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 ">
                        <span className="text-base font-bold">Faturamento acumulado</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">
                              {toCurrency(item?.faturamento_acumulado)}
                            </Text>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="text-base font-bold">Faixa atual</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">{item?.faixa_atual}</Text>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="text-base font-bold">Porcentagem do Limite</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">{item?.limite_atingido}%</Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <Paginator pagination={pagination} />
            </div>
          )}
        </div>
      </FormBox>
    </div>
  );
}
