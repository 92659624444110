/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Checkbox,
} from '@chakra-ui/react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'sonner';
import { useMutation } from '@tanstack/react-query';
import { INota, useNotas } from '../../hooks/useNotas';
import { TextAreaInputForm } from '../inputs/forms/TextAreaInputForm';

const schema = yup.object().shape({
  descricao: yup.string().required('Descrição obrigatória'),
  outros: yup.boolean().default(true), // Definindo um valor padrão
});

interface IForm {
  descricao: string;
  outros: boolean;
}

const CreateNotaModal = ({ screen, id, dateStart, handleReFecth, children }: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { createNota } = useNotas();

  const { mutateAsync } = useMutation({
    mutationFn: createNota,
  });

  const formMethods = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      descricao: '',
      outros: true,
    },
  });

  const onSubmit = async (values: IForm) => {
    try {
      await mutateAsync({
        id,
        descricao: values.descricao,
        tela: screen,
        dt_anotacao: dateStart,
        outros: values?.outros,
      } as unknown as INota);
      handleReFecth();
      formMethods.reset();
      onClose();
      toast.success(`Nota salva com sucesso!`, {
        position: 'top-right',
      });
    } catch (error: any) {
      toast.error(
        error.response?.data?.detail ?? 'Erro interno no sistema, tente novamente mais tarde.',
        { position: 'top-right' },
      );
    }
  };

  return (
    <>
      {children ? (
        React.cloneElement(children, { onClick: onOpen })
      ) : (
        <Button onClick={onOpen}>Criar uma nota</Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Criar uma nota</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <TextAreaInputForm
                  name="descricao"
                  label="Descrição"
                  placeholder="Adicione uma descrição"
                />
                <Controller
                  name="outros"
                  control={formMethods.control}
                  render={({ field: { value, onChange, ref, ...rest } }) => (
                    <Checkbox
                      isChecked={Boolean(value)}
                      defaultChecked
                      onChange={e => onChange(e.target.checked)}
                      ref={ref}
                      {...rest}
                    >
                      Mudar status para Outros
                    </Checkbox>
                  )}
                />

                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Fechar
                  </Button>
                  <Button type="submit">Salvar</Button>
                </ModalFooter>
              </form>
            </FormProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateNotaModal;
