/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Input, useBreakpointValue, Text } from '@chakra-ui/react';
import moment from 'moment';
import { CalendarPlus } from 'phosphor-react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';
import { createSearchParams } from 'react-router-dom';

registerLocale('pt-BR', ptBR);

const initialDates = {
  date_start: moment().subtract(6, 'days').toDate(),
  date_end: moment().toDate(),
};

export const DateToolbarFilter = ({ setFilters, filters, showMonthYearOnly }: any) => {
  const [dateSelected, setDateSelected] = useState(1);
  const [isDateOpened, setIsDateOpened] = useState(false);
  const [dates, setDates] = useState<any>(initialDates);
  const [datesShow, setDatesShow] = useState<any>(initialDates);
  const size = useBreakpointValue({ base: 16, md: 20, lg: 24 });
  const arrayOP = showMonthYearOnly
    ? ['Mês anterior', 'Mês atual']
    : ['Mês anterior', 'Mês atual', '15 dias', '7 dias'];

  useEffect(() => {
    const startDate = filters?.get('date_start') || initialDates.date_start;
    const endDate = filters?.get('date_end') || initialDates.date_end;

    if (startDate && endDate) {
      setDates({
        date_start: moment(startDate).toDate(),
        date_end: moment(endDate).toDate(),
      });
      setDatesShow({
        date_start: moment(startDate).toDate(),
        date_end: moment(endDate).toDate(),
      });
      const daysDiff = moment(endDate).diff(moment(startDate), 'days');

      if (daysDiff > 30) setDateSelected(0);
      else if (daysDiff === 29)
        setDateSelected(4 - 1); // Mês anterior
      else if (daysDiff === 30)
        setDateSelected(4); // Mês anterior
      else if (daysDiff === 14)
        setDateSelected(2); // 15 dias
      else if (daysDiff === 6) {
        setDateSelected(1);
      } else setDateSelected(0); // 7 dias
    } else {
      setDates(initialDates);
      setDatesShow(initialDates);
      setDateSelected(1); // Reseta para mês atual por padrão
    }
  }, []);

  return (
    <Box>
      <div className="flex gap-2 ">
        {showMonthYearOnly ? (
          <Text className="flex gap-1 pb-4">
            <Text className="font-bold">Mês inicial: </Text>
            {moment(datesShow?.date_end).format('MM/YYYY')}
          </Text>
        ) : (
          <>
            <Text className="flex gap-1 pb-4">
              <Text className="font-bold">Data inicial: </Text>
              {moment(datesShow?.date_start).format('DD/MM/YYYY')}
            </Text>
            <Text className="flex gap-1 pb-4">
              <Text className="font-bold">Data final: </Text>
              {moment(datesShow?.date_end).format('DD/MM/YYYY')}
            </Text>
          </>
        )}
      </div>

      <div
        className={
          showMonthYearOnly
            ? 'grid w-full grid-flow-row grid-cols-3 gap-2 md:grid-cols-3 xl:grid-cols-3'
            : 'grid w-full grid-flow-row grid-cols-3 gap-2 md:grid-cols-5 xl:grid-cols-5'
        }
      >
        {arrayOP.map((label, index) => (
          <Button
            key={index}
            variant="outline"
            colorScheme="none"
            borderBottom={dateSelected === 4 - index ? '5px solid' : '1px solid'}
            borderBottomColor={dateSelected === 4 - index ? 'theme.light.tertiary' : ''}
            mr={{ base: '10px', md: '20px' }}
            onClick={() => {
              const start =
                index === 0
                  ? moment().subtract(1, 'months').startOf('month')
                  : index === 1
                    ? moment().startOf('month')
                    : index === 2
                      ? moment()
                      : moment();
              const dateEnd =
                index === 0
                  ? moment().subtract(1, 'months').endOf('month')
                  : index === 1
                    ? moment().endOf('month')
                    : index === 2
                      ? moment().add(14, 'days')
                      : moment().add(6, 'days');
              setFilters(
                createSearchParams({
                  ...Object.fromEntries(filters),
                  date_start: moment(start).format('YYYY-MM-DD'),
                  date_end: moment(dateEnd).format('YYYY-MM-DD'),
                }),
              );
              setDatesShow({
                date_start: moment(start).format('YYYY-MM-DD'),
                date_end: moment(dateEnd).format('YYYY-MM-DD'),
              });
              setDateSelected(4 - index);
            }}
            _hover={{
              opacity: 0.6,
            }}
            fontSize={{ base: '12px', md: '14px', lg: '16px' }}
            px={{ base: '8px', md: '12px', lg: '16px' }}
          >
            {label}
          </Button>
        ))}

        <Button
          variant="outline"
          colorScheme="none"
          borderBottom={dateSelected === 0 ? '5px solid' : '1px solid'}
          borderBottomColor={dateSelected === 0 ? 'theme.light.tertiary' : ''}
          onClick={() => {
            setIsDateOpened(!isDateOpened);
          }}
          _hover={{
            opacity: 0.6,
          }}
        >
          <CalendarPlus size={size} />
        </Button>
      </div>

      {isDateOpened && (
        <Flex
          justifyContent={{ base: 'flex-end', md: 'end' }}
          mt="15px"
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'flex-end', md: 'center' }}
        >
          <Flex
            maxWidth={{ base: '100%', md: '400px' }}
            justifyContent="space-evenly"
            alignItems="center"
            mb={{ base: '10px', md: '0' }}
          >
            {showMonthYearOnly ? (
              <Box maxWidth="50%">
                <DatePicker
                  selected={dates.date_start}
                  onChange={date => {
                    const firstDayOfMonth = moment(date).startOf('month').toDate();
                    const lastDayOfMonth = moment(date).endOf('month').toDate();
                    const firstDayOfPreviousMonthShow = moment(date)
                      .subtract(1, 'months')
                      .startOf('month')
                      .toDate();

                    // Último dia do mês atual (data selecionada)
                    const lastDayOfCurrentMonthShow = moment(date).endOf('month').toDate();
                    setDates(old => ({
                      ...old,
                      date_start: firstDayOfMonth,
                      date_end: lastDayOfMonth,
                    }));

                    setDatesShow(old => ({
                      ...old,
                      date_start: firstDayOfPreviousMonthShow,
                      date_end: lastDayOfCurrentMonthShow,
                    }));
                  }}
                  selectsStart
                  locale="pt-BR"
                  dateFormat="MM yyyy"
                  startDate={dates.date_start}
                  endDate={dates.date_end}
                  customInput={<Input variant="flushed" w="100%" textAlign="center" />}
                  showMonthYearPicker
                />
              </Box>
            ) : (
              <>
                <Box maxWidth="25%">
                  <DatePicker
                    selected={dates.date_start}
                    onChange={date => {
                      setDates(old => ({ ...old, date_start: date }));
                      setDatesShow(old => ({ ...old, date_start: date }));
                    }}
                    selectsStart
                    locale="pt-BR"
                    dateFormat="dd MMM yyyy"
                    startDate={dates.date_start}
                    endDate={dates.date_end}
                    customInput={<Input variant="flushed" w="100%" textAlign="center" />}
                  />
                </Box>
                <Box maxWidth="25%">
                  <DatePicker
                    selected={dates.date_end}
                    onChange={date => {
                      setDates(old => ({ ...old, date_end: date }));
                      setDatesShow(old => ({ ...old, date_end: date }));
                    }}
                    selectsEnd
                    locale="pt-BR"
                    dateFormat="dd MMM yyyy"
                    startDate={dates.date_start}
                    endDate={dates.date_end}
                    customInput={<Input variant="flushed" w="100%" textAlign="center" />}
                  />
                </Box>
              </>
            )}
          </Flex>
          <Button
            variant="outline"
            colorScheme="none"
            color="text.secondary"
            onClick={() => {
              showMonthYearOnly
                ? setDatesShow({
                    date_start: moment(datesShow.date_start).format('YYYY-MM-DD'),
                    date_end: moment(datesShow.date_end).format('YYYY-MM-DD'),
                  })
                : setDatesShow({
                    date_start: moment(dates.date_start).format('YYYY-MM-DD'),
                    date_end: moment(dates.date_end).format('YYYY-MM-DD'),
                  });

              setFilters(
                createSearchParams({
                  ...Object.fromEntries(filters),
                  date_start: moment(dates.date_start).format('YYYY-MM-DD'),
                  date_end: moment(dates.date_end).format('YYYY-MM-DD'),
                }),
              );
              setDateSelected(0);
            }}
            _hover={{
              opacity: 0.6,
            }}
            fontSize={{ base: '12px', md: '14px', lg: '16px' }}
            px={{ base: '8px', md: '12px', lg: '16px' }}
            alignSelf={{ base: 'flex-end', md: 'auto' }}
          >
            Selecionar
          </Button>
        </Flex>
      )}
    </Box>
  );
};
