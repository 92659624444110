import { QueryFunctionContext } from '@tanstack/react-query';
import { api } from '../services/api';
import { IData, IDataNoPagination } from '../types/shared';

export interface INota {
  id?: number;
  descricao: string;
  tela: string;
  dt_anotacao: string;
}

interface INotaFilter {
  empresaId: string;
}

export const useNotas = () => {
  const getNotas = async (key: QueryFunctionContext): Promise<IData<INota[]>> => {
    const filters = key.queryKey?.[1] ?? ({} as any);
    const { empresaId, ...filteredParams } = filters;
    const res = await api.get<IData<INota[]>>(`/api/v1/empresas/${empresaId}/anotacoes/`, {
      params: filteredParams,
    });
    return res.data;
  };

  const getNota = async (key: QueryFunctionContext): Promise<IDataNoPagination<INota>> => {
    const { empresaId } = key.queryKey[1] as INotaFilter;
    const res = await api.get<IDataNoPagination<INota>>(`/api/v1/empresas/${empresaId}/anotacoes/`);
    return res.data;
  };

  const createNota = async (data: INota): Promise<IData<INota>> => {
    const { id, ...filteredData } = data;

    const res = await api.post<IData<INota>>(`/api/v1/empresas/${id}/anotacoes/`, filteredData);
    return res.data;
  };

  const updateNota = async (data: INota): Promise<IData<INota>> => {
    const res = await api.put<IData<INota>>(`/api/v1/empresas/${data.id}/anotacoes/`, data);
    return res.data;
  };

  const deleteNota = async (empresaId: string) => {
    const res = await api.delete(`/api/v1/empresas/${empresaId}/anotacoes/`);
    return res.data;
  };

  return {
    getNotas,
    getNota,

    createNota,
    updateNota,
    deleteNota,
  };
};
