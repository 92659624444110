/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from '../datatable';
import { INota, useNotas } from '../../hooks/useNotas';
import { IData, IPagination } from '../../types/shared';
import CreateNotaModal from '../createNota';
import { formatDate } from '../../utils/FormatFunctions';

const ViewNotaModal = ({ screen, id, dateStart, dateEnd, children }: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getNotas } = useNotas();
  const columns: ColumnDef<INota>[] = [
    {
      accessorKey: 'usuario.nome',
      header: 'Usuário',
    },
    {
      accessorKey: 'dt_anotacao',
      header: 'Data',
      cell: ({ row }) => {
        const value = row.original.dt_anotacao;
        return formatDate(value);
      },
    },
    {
      accessorKey: 'descricao',
      header: 'Anotação',
    },
  ];
  const [filters] = useSearchParams({
    page: '1',
    size: '10',
    empresaId: id,
    screen_data: screen,
    date_start: dateStart, // Convertendo para string
    date_end: dateEnd, // Convertendo para string
  });
  const {
    data: dataNotasList,
    isFetching: isFetchingCompanyList,
    refetch,
  } = useQuery<IData<INota[]>>({
    queryKey: ['CompaniesList', Object.fromEntries([...Array.from(filters)])],
    queryFn: getNotas,
    enabled: isOpen,
    refetchOnWindowFocus: true, // Refaz a requisição quando a janela recebe foco
    refetchOnReconnect: true, // Refaz a requisição quando há reconexão de rede
  });

  const { items: notas, ...pagination } = dataNotasList?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  return (
    <>
      {children ? (
        React.cloneElement(children, { onClick: onOpen })
      ) : (
        <Button onClick={onOpen}>Visualizar PDF</Button>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Anotações</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="flex justify-end pb-5">
              <div />
              <div className="flex">
                <CreateNotaModal
                  screen={screen}
                  id={id}
                  dateStart={dateStart}
                  handleReFecth={refetch}
                >
                  <Button
                    variant="outline"
                    h="fit-content"
                    w="100%"
                    cursor="pointer"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="full"
                    border="2px solid"
                    borderColor="#718EBF"
                    bg="white"
                    p={2}
                  >
                    <Text className="text-sm" color="#718EBF">
                      Criar anotação
                    </Text>
                  </Button>
                </CreateNotaModal>
              </div>
            </div>

            <div className="flex flex-col overflow-y-auto">
              <DataTable<INota>
                columns={columns}
                data={notas}
                pagination={pagination}
                emptyMsg="Nenhuma anotação encontrada."
                loadingMsg="Carregando anotações..."
                isFetching={isFetchingCompanyList}
              />
            </div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ViewNotaModal;
