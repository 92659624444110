import React, { useState, useEffect } from 'react';
import { Box, Button, Text, Input, Flex } from '@chakra-ui/react';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import { createSearchParams } from 'react-router-dom';

registerLocale('pt-BR', ptBR);

export const YearSelector = ({ setFilters, filters }: any) => {
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [datesShow, setDatesShow] = useState({
    date_start: moment().startOf('year').toDate(),
    date_end: moment().endOf('year').toDate(),
  });

  useEffect(() => {
    const startDate = filters?.get('date_start') || moment().startOf('year').toDate();
    const endDate = filters?.get('date_end') || moment().endOf('year').toDate();

    setDatesShow({
      date_start: moment(startDate).startOf('year').toDate(),
      date_end: moment(endDate).endOf('year').toDate(),
    });
  }, [filters]);

  const handleYearChange = (date: Date) => {
    const year = moment(date).year();
    setSelectedYear(year);
    setDatesShow({
      date_start: moment(date).startOf('year').toDate(),
      date_end: moment(date).endOf('year').toDate(),
    });
  };

  const applyFilter = () => {
    setFilters(
      createSearchParams({
        ...Object.fromEntries(filters),
        date_start: moment(datesShow.date_start).format('YYYY-MM-DD'),
        date_end: moment(datesShow.date_end).format('YYYY-MM-DD'),
      }),
    );
  };

  return (
    <Box>
      <Text className="flex gap-1 pb-4">
        <Text className="font-bold">Ano selecionado: {selectedYear} </Text>
      </Text>
      <Flex alignItems="center" gap={4}>
        <DatePicker
          selected={datesShow.date_start}
          onChange={handleYearChange}
          locale="pt-BR"
          dateFormat="yyyy"
          showYearPicker
          customInput={
            <Input variant="flushed" w="100%" textAlign="center" readOnly cursor="pointer" />
          }
        />
        <Button onClick={applyFilter} colorScheme="blue">
          Selecionar Ano
        </Button>
      </Flex>
    </Box>
  );
};
