/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { PageBox } from '../../components/pageBox';
import { ReturnButton } from '../../components/buttons/ReturnButton';
// import { InputForm } from '../../components/inputs/forms/InputForm';
import { FormBox } from '../../components/formBox';
import { IData } from '../../types/shared';
import { useCompanies } from '../../hooks/useCompanies';
import { formatCNPJ } from '../../utils/FormatFunctions';

export function BreaksPage() {
  const location = useLocation();
  const [company] = useState(location.state?.company);
  const { getQuebrasWithId } = useCompanies();
  const { itemId } = useParams();
  const [searchParams] = useSearchParams();
  const dateStart = searchParams.get('date_start');
  const dateEnd = searchParams.get('date_end');

  const [NFEList, setNFEList] = useState<number[]>([]);
  const [NFSEList, setNFSEList] = useState<number[]>([]);
  const [NFCEList, setNFCEList] = useState<number[]>([]);

  const [filtersNFE, setFiltersNFE] = useState({
    page: '1',
    size: '30',
    nf_type: 'nfe',
    date_start: dateStart,
    date_end: dateEnd,
    companyId: itemId,
  });
  const [filtersNFSE, setFiltersNFSE] = useState({
    page: '1',
    size: '30',
    nf_type: 'nfse',
    date_start: dateStart,
    date_end: dateEnd,
    companyId: itemId,
  });
  const [filtersNFCE, setFiltersNFCE] = useState({
    page: '1',
    size: '30',
    nf_type: 'nfce',
    date_start: dateStart,
    date_end: dateEnd,
    companyId: itemId,
  });

  const { data: dataBreaksNFE, isFetching: isFetchingBreaksNFE } = useQuery<IData<number[]>>({
    queryKey: ['breaksNFE', filtersNFE],
    queryFn: getQuebrasWithId,
  });
  const { data: dataBreaksNFSE, isFetching: isFetchingBreaksNFSE } = useQuery<IData<number[]>>({
    queryKey: ['breaksNFSE', filtersNFSE],
    queryFn: getQuebrasWithId,
  });
  const { data: dataBreaksNFCE, isFetching: isFetchingBreaksNFCE } = useQuery<IData<number[]>>({
    queryKey: ['breaksNFCE', filtersNFCE],
    queryFn: getQuebrasWithId,
  });

  const dataNFE = dataBreaksNFE?.data;
  const dataNFSE = dataBreaksNFSE?.data;
  const dataNFCE = dataBreaksNFCE?.data;

  useEffect(() => {
    if (dataNFE) {
      if (dataNFE?.page === 1) {
        setNFEList(dataNFE.items);
      } else {
        setNFEList(old => [...old, ...dataNFE.items]);
      }
    }
  }, [dataNFE]);

  useEffect(() => {
    if (dataNFSE) {
      if (dataNFSE?.page === 1) {
        setNFSEList(dataNFSE.items);
      } else {
        setNFSEList(old => [...old, ...dataNFSE.items]);
      }
    }
  }, [dataNFSE]);

  useEffect(() => {
    if (dataNFCE) {
      if (dataNFCE?.page === 1) {
        setNFCEList(dataNFCE.items);
      } else {
        setNFCEList(old => [...old, ...dataNFCE.items]);
      }
    }
  }, [dataNFCE]);

  return (
    <PageBox>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col items-center justify-between gap-3 py-5 lg:flex-row">
          <div className="flex content-start md:w-1/3 lg:content-center ">
            <ReturnButton label="Voltar" />
            <div className="flex flex-col items-center gap-3 p-5 lg:flex-row">
              <div className="h-full">
                <div className="flex items-center justify-center rounded-full bg-[#FFE0EB] p-3">
                  <IoCloseCircleOutline color="#FF82AC" className="size-12" />
                </div>
              </div>
              <div className="flex w-full flex-col gap-1">
                <Text fontWeight="bold" fontSize="16px">
                  Quebras
                </Text>
              </div>
            </div>
          </div>
          <div className="mb-5 grid grid-cols-1 gap-3 md:grid-cols-3">
            <Flex className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5">
              <div className="flex w-full flex-col ">
                <Text fontSize="10px" color="#718EBF">
                  ID
                </Text>
                <Text fontSize="14px" fontWeight="bold">
                  {company?.id}
                </Text>
              </div>
            </Flex>
            <Flex className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5">
              <div className="flex w-full flex-col ">
                <Text fontSize="10px" color="#718EBF">
                  CNPJ
                </Text>
                <Text fontSize="14px" fontWeight="bold">
                  {formatCNPJ(company?.cnpj)}
                </Text>
              </div>
            </Flex>
            <Flex className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5">
              <div className="flex w-full flex-col ">
                <Text fontSize="10px" color="#718EBF">
                  Razão social
                </Text>
                <Text fontSize="14px" fontWeight="bold">
                  {company?.razao_social}
                </Text>
              </div>
            </Flex>
          </div>
        </div>

        <FormBox>
          <div className="flex flex-col gap-4  p-4">
            <div className="grid grid-flow-row grid-cols-1 items-center justify-between gap-2 text-center lg:grid-cols-2">
              <div className="flex gap-4 rounded-lg border border-border bg-body px-8 py-4 shadow-lg lg:flex-col">
                <div className="flex flex-col gap-2">
                  <span className="text-4xl font-bold">NFE</span>
                  <div>
                    {isFetchingBreaksNFE && NFEList.length === 0 ? (
                      <div className="flex flex-col items-center gap-4">
                        <Spinner size="lg" color="primary" />
                        <span className="text-xl font-semibold">Carregando...</span>
                      </div>
                    ) : dataNFE?.items.length === 0 ? (
                      <div className="flex flex-col items-center gap-4">Nenhum item encontrado</div>
                    ) : (
                      NFEList?.map(item => (
                        <span className="text-sm text-[#718EBF]">{`${item}, `}</span>
                      ))
                    )}
                  </div>

                  {isFetchingBreaksNFE && NFEList.length > 0 && (
                    <div className="flex flex-col items-center gap-4">
                      <Spinner size="lg" color="primary" />
                    </div>
                  )}

                  {dataNFE && NFEList.length > 0 && dataNFE.next_page && (
                    <div>
                      <Button
                        variant="ghost"
                        onClick={() =>
                          setFiltersNFE(old => ({
                            ...old,
                            page: (Number(old.page) + 1).toString(),
                          }))
                        }
                      >
                        Ver mais
                      </Button>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex gap-4 rounded-lg border border-border bg-body px-8 py-4 text-center shadow-lg lg:flex-col">
                <div className="flex flex-col gap-2">
                  <span className="text-4xl font-bold">NFSE</span>
                  <div>
                    {isFetchingBreaksNFSE && NFSEList.length === 0 ? (
                      <div className="flex flex-col items-center gap-4">
                        <Spinner size="lg" color="primary" />
                        <span className="text-xl font-semibold">Carregando...</span>
                      </div>
                    ) : dataNFSE?.items.length === 0 ? (
                      <div className="flex flex-col items-center gap-4">Nenhum item encontrado</div>
                    ) : (
                      dataNFSE?.items?.map(item => (
                        <span className="text-sm text-[#718EBF]">{`${item}, `}</span>
                      ))
                    )}
                  </div>

                  {isFetchingBreaksNFSE && NFSEList.length > 0 && (
                    <div className="flex flex-col items-center gap-4">
                      <Spinner size="lg" color="primary" />
                    </div>
                  )}

                  {dataNFSE && NFSEList.length > 0 && dataNFSE.next_page && (
                    <div>
                      <Button
                        variant="ghost"
                        onClick={() =>
                          setFiltersNFSE(old => ({
                            ...old,
                            page: (Number(old.page) + 1).toString(),
                          }))
                        }
                      >
                        Ver mais
                      </Button>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex gap-4 rounded-lg border border-border bg-body px-8 py-4 text-center shadow-lg lg:flex-col">
                <div className="flex flex-col gap-2">
                  <span className="text-4xl font-bold">NFCE</span>
                  <div>
                    {isFetchingBreaksNFCE && NFCEList.length === 0 ? (
                      <div className="flex flex-col items-center gap-4">
                        <Spinner size="lg" color="primary" />
                        <span className="text-xl font-semibold">Carregando...</span>
                      </div>
                    ) : dataNFCE?.items.length === 0 ? (
                      <div className="flex flex-col items-center gap-4">Nenhum item encontrado</div>
                    ) : (
                      NFCEList?.map(item => (
                        <span className="text-sm text-[#718EBF]">{`${item}, `}</span>
                      ))
                    )}
                  </div>

                  {isFetchingBreaksNFCE && NFCEList.length > 0 && (
                    <div className="flex flex-col items-center gap-4">
                      <Spinner size="lg" color="primary" />
                    </div>
                  )}

                  {dataNFCE && NFCEList.length > 0 && dataNFCE.next_page && (
                    <div>
                      <Button
                        variant="ghost"
                        onClick={() =>
                          setFiltersNFCE(old => ({
                            ...old,
                            page: (Number(old.page) + 1).toString(),
                          }))
                        }
                      >
                        Ver mais
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </FormBox>
      </div>
    </PageBox>
  );
}
