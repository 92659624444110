import React, { useState } from 'react';
import { CaretDoubleLeft, CaretDoubleRight, CaretLeft, CaretRight } from 'phosphor-react';
import { IPagination } from '../../types/shared';

interface PaginatorProps {
  pagination: IPagination;
  setFilters: any;
}

export const Paginator = ({ pagination, setFilters }: PaginatorProps) => {
  const [currentPage, setCurrentPage] = useState(pagination.page);

  const updatePage = (item: any) => {
    setCurrentPage(item);
    setFilters(prev => ({
      ...prev,
      page: item?.page,
    }));
  };

  return (
    <div className="flex justify-end space-x-2 pt-4">
      <div className="text-muted-foreground self-center text-sm">
        Página(s) {currentPage} de {pagination.pages}
      </div>

      <button
        type="button"
        aria-label="primeira"
        className="flex items-center justify-center rounded-md border border-border bg-body px-1 hover:opacity-80 disabled:opacity-50"
        onClick={pagination.previous_page !== null ? () => updatePage({ page: '1' }) : undefined}
        disabled={pagination.previous_page === null}
      >
        <CaretDoubleLeft size={16} weight="bold" />
      </button>

      <button
        type="button"
        aria-label="anterior"
        className="flex items-center justify-center rounded-md border border-border bg-body px-1 hover:opacity-80 disabled:opacity-50"
        onClick={
          pagination.previous_page !== null
            ? () =>
                updatePage({
                  page: pagination.previous_page ? pagination.previous_page.toString() : '1',
                })
            : undefined
        }
        disabled={pagination.previous_page === null}
      >
        <CaretLeft size={16} weight="bold" />
      </button>

      <button
        type="button"
        aria-label="proxima"
        className="flex items-center justify-center rounded-md border border-border bg-body px-1 hover:opacity-80 disabled:opacity-50"
        onClick={
          pagination.next_page !== null
            ? () =>
                updatePage({
                  page: pagination.next_page ? pagination.next_page.toString() : '1',
                })
            : undefined
        }
        disabled={pagination.next_page === null}
      >
        <CaretRight size={16} weight="bold" />
      </button>

      <button
        type="button"
        aria-label="ultima"
        className="flex items-center justify-center rounded-md border border-border bg-body px-1 hover:opacity-80 disabled:opacity-50"
        onClick={
          pagination.next_page !== null
            ? () => updatePage({ page: pagination.pages.toString() })
            : undefined
        }
        disabled={pagination.next_page === null}
      >
        <CaretDoubleRight size={16} weight="bold" />
      </button>
    </div>
  );
};
