/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createSearchParams, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { Button, Flex, HStack, Text } from '@chakra-ui/react';
import { ReturnButton } from '../../../../../components/buttons/ReturnButton';
import { IData, IPagination } from '../../../../../types/shared';
import { ISaida, useCompanies } from '../../../../../hooks/useCompanies';
import { formatCNPJ, formatDate, toCurrency } from '../../../../../utils/FormatFunctions';
import { DataTable } from '../../../../../components/datatable';
import { FormBox } from '../../../../../components/formBox';
import SelectInputForm from '../../../../../components/inputs/forms/SelectInputForm';
import { DateToolbarFilter } from '../../../../../components/DateToolbarFilter';
import { useSpreadsheet } from '../../../../../hooks/useSpreadsheet';

export function ModulesHomeIntegrationXmlAnalysisSHowNotasPage() {
  const { getCompaniesSaidasXml } = useCompanies();
  const { itemId } = useParams();
  const location = useLocation();

  // 1️⃣ Recupera do localStorage ou do estado da navegação
  const company = useMemo(() => {
    try {
      const savedCompany = localStorage.getItem('selectedCompany');
      return location.state?.company || (savedCompany ? JSON.parse(savedCompany) : null);
    } catch (error) {
      return null;
    }
  }, [location.state?.company]);

  // ✅ Atualiza o localStorage quando `company` mudar
  useEffect(() => {
    if (company) {
      localStorage.setItem('selectedCompany', JSON.stringify(company));
    }
  }, [company]);

  const { getSpreadsheet } = useSpreadsheet();
  // Obtém os parâmetros da query string
  const queryParams = new URLSearchParams(location.search);
  const searchParamStart = queryParams.get('date_start');
  const searchParamEnd = queryParams.get('date_end');

  const columns: ColumnDef<ISaida>[] = [
    {
      accessorKey: 'nome_cliente',
      header: 'Nome do cliente',
    },
    {
      accessorKey: 'cidade_cliente',
      header: 'Cidade do cliente',
    },
    {
      accessorKey: 'data_emissao',
      header: 'Data emissão',
      cell: ({ row }) => {
        const value = row.original.data_emissao;
        return formatDate(value);
      },
    },
    {
      accessorKey: 'nume_sai',
      header: 'Número de saída',
    },
    {
      accessorKey: 'seri_sai',
      header: 'Série de saida',
    },
    {
      accessorKey: 'sigl_est',
      header: 'Sigla do estado',
    },
    {
      accessorKey: 'municipio_incidencia',
      header: 'Município de incidência',
    },
    {
      accessorKey: 'tipo_iss',
      header: 'Tipo de iss',
    },
    {
      accessorKey: 'status_nfe',
      header: 'Status da NFE',
    },
    {
      accessorKey: 'cnae',
      header: 'CNAE',
    },
    {
      accessorKey: 'cnae_descricao',
      header: 'Descrição da CNAE',
    },
    {
      accessorKey: 'tributo.iss_retido',
      header: 'ISS retido',
      cell: ({ row }) => {
        return row.original.tributo !== null ? toCurrency(row.original.tributo.iss_retido) : 'R$ 0';
      },
    },
    {
      accessorKey: 'tributo.valor_iss',
      header: 'Valor ISS',
      cell: ({ row }) => {
        return row.original.tributo !== null ? toCurrency(row.original.tributo.valor_iss) : 'R$ 0';
      },
    },
    {
      accessorKey: 'tributo.inss',
      header: 'INSS',
      cell: ({ row }) => {
        return row.original.tributo !== null ? toCurrency(row.original.tributo.inss) : 'R$ 0';
      },
    },
    {
      accessorKey: 'tributo.csll',
      header: 'CSLL',
      cell: ({ row }) => {
        return row.original.tributo !== null ? toCurrency(row.original.tributo.csll) : 'R$ 0';
      },
    },
    {
      accessorKey: 'tributo.pis',
      header: 'PIS',
      cell: ({ row }) => {
        return row.original.tributo !== null ? toCurrency(row.original.tributo.pis) : 'R$ 0';
      },
    },
    {
      accessorKey: 'tributo.cofins',
      header: 'CONFINS',
      cell: ({ row }) => {
        return row.original.tributo !== null ? toCurrency(row.original.tributo.cofins) : 'R$ 0';
      },
    },
    {
      accessorKey: 'tributo.valor_nfe',
      header: 'Valor NFE',
      cell: ({ row }) => {
        return row.original.tributo !== null ? toCurrency(row.original.tributo.valor_nfe) : 'R$ 0';
      },
    },
    {
      accessorKey: 'inscricao_municipal',
      header: 'Inscrição municipal',
    },
    {
      accessorKey: 'codigo_verificacao',
      header: 'Código de verificação',
    },
  ];

  const [filters, setFilters] = useSearchParams(
    createSearchParams({
      page: '1',
      size: '10',
      search: '',
      empresa_id: itemId || '',
      especie: 'nfse',
    }),
  );

  const [filtersDownLoadXLSX] = useSearchParams(
    createSearchParams({
      date_start: searchParamStart ?? '',
      date_end: searchParamEnd ?? '',
      empresa_id: itemId || '',
    }),
  );
  const { mutateAsync } = useMutation({
    mutationFn: getSpreadsheet,
  });

  const filtersFormMethods = useForm<{
    empresa_id: string;
    page: string;
    intermunicipal?: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      intermunicipal: filters.get('intermunicipal') ?? undefined,
    },
  });
  const debounced = useDebounceCallback(values => {
    const currentFilters = Object.fromEntries([...Array.from(filters)]);

    if (values?.intermunicipal?.value) {
      setFilters(
        createSearchParams({
          ...currentFilters,
          intermunicipal: values?.intermunicipal?.value || '',
          empresa_id: itemId || '',
          page: '1',
        }),
      );
    } else {
      setFilters(
        createSearchParams({
          ...currentFilters,
          empresa_id: itemId || '',
          page: '1',
        }),
      );
    }
  }, 200);

  useEffect(() => {
    const subscription = filtersFormMethods.watch(debounced);
    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch]);

  const { data: dataCompaniesList, isFetching: isFetchingCompanyList } = useQuery<IData<ISaida[]>>({
    queryKey: ['CompaniesList', Object.fromEntries([...Array.from(filters)])],
    queryFn: getCompaniesSaidasXml,
  });

  const { items: companiesList, ...pagination } = dataCompaniesList?.data ?? {
    items: [],
    ...({} as IPagination),
  };
  const handleDownload = async () => {
    await mutateAsync(Object.fromEntries([...Array.from(filtersDownLoadXLSX)]));
  };
  return (
    <div className="flex w-full flex-col gap-10">
      <ReturnButton
        label="Voltar para página anterior"
        previousPage="modulesHome/integration/xmlAnalysis"
      />
      <div className="flex justify-end gap-3 py-4">
        <Button onClick={handleDownload}>Exportar XMLS</Button>
      </div>
      <HStack justify="flex-end" mt={8}>
        <DateToolbarFilter setFilters={setFilters} filters={filters} showMonthYearOnly />
      </HStack>
      <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
        <Flex className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5">
          <div className="flex w-full flex-col ">
            <Text fontSize="10px" color="#718EBF">
              ID
            </Text>
            <Text fontSize="14px" fontWeight="bold">
              {company?.id}
            </Text>
          </div>
        </Flex>
        <Flex className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5">
          <div className="flex w-full flex-col ">
            <Text fontSize="10px" color="#718EBF">
              CNPJ
            </Text>
            <Text fontSize="14px" fontWeight="bold">
              {company?.cnpj ? formatCNPJ(company?.cnpj) : ''}
            </Text>
          </div>
        </Flex>
        <Flex className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5">
          <div className="flex w-full flex-col ">
            <Text fontSize="10px" color="#718EBF">
              Razão social
            </Text>
            <Text fontSize="14px" fontWeight="bold">
              {company?.razao_social}
            </Text>
          </div>
        </Flex>
      </div>
      <FormBox>
        <div className="flex flex-col gap-4  p-4">
          <div className="flex flex-col justify-between gap-2">
            <FormProvider {...filtersFormMethods}>
              <SelectInputForm<string>
                name="intermunicipal"
                options={[
                  { label: 'Municipais', value: 1 },
                  { label: 'Outros Municípios', value: 2 },
                  { label: 'Não identificado', value: 0 },
                ]}
              />
            </FormProvider>
          </div>
          <DataTable<ISaida>
            columns={columns}
            data={companiesList}
            pagination={pagination}
            emptyMsg="Nenhuma Nota encontrada."
            loadingMsg="Carregando Notas..."
            isFetching={isFetchingCompanyList}
            xmlPagination
          />
        </div>
      </FormBox>
    </div>
  );
}
